<template>
  <div class="text-center">
    <v-dialog v-model="openDialog" width="500" persistent>
      <v-card align="center">
        <v-card-text class="pt-5">
          <p class="text-h5">{{ $t("coastDialogs.congratulation") }}</p>
          <p>{{ $t("coastDialogs.changeMapInProgress") }}</p>
          <v-btn text elevation="0" color="primary" @click="closeDialog"
            >{{ $t("coastDialogs.return") }}
          </v-btn>
          <v-btn
            text
            elevation="0"
            color="primary"
            :to="{
              name: 'MapView'
            }"
            >{{ $t("coastDialogs.toMap") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ChangeMapInfoDialog",
  props: {
    openDialog: Boolean
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    }
  }
};
</script>

<style scoped></style>
