<template>
  <core-menu>
    <region-menu-header
      name="analyse"
      :icon="'mdi-compare'"
      :disable-region-menu="true"
      :disable-data-request="true"
    />
    <v-divider />
    <v-divider />
    <div>
      <v-row class="pa-2">
        <v-col cols="10" class="pl-5">
          <span class="subtitle-1 font-weight-bold">{{
            $t("changeDetectionTool.changeDetection")
          }}</span>
        </v-col>
        <v-col cols="2">
          <title-description-info
            :title="$t('coastDialogs.infoHeader')"
            :description="$t('coastDialogs.infoText')"
          />
        </v-col>
      </v-row>
      <change-detection-tool />
    </div>
  </core-menu>
</template>

<script>
import CoreMenu from "@/core/components/menu/CoreMenu";
import RegionMenuHeader from "@/core/components/menu/RegionMenuHeader";
import TitleDescriptionInfo from "@/core/components/infos/TitleDescriptionInfo";
import ChangeDetectionTool from "@/core/components/menu/eotool/analyse/ChangeDetectionTool.vue";

export default {
  name: "ChangeMapMenu",
  components: {
    CoreMenu,
    ChangeDetectionTool,
    RegionMenuHeader,
    TitleDescriptionInfo
  },
  data: () => ({
    activeComponents: []
  })
};
</script>

<style scoped></style>
