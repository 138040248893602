<template>
  <v-container class="px-5" style="align-content: center">
    <div v-if="!layerSelection.length">
      {{ $t("noRasterLayerAvailable") }}
    </div>
    <div v-else>
      <span>Choose one layer to create a change map</span>
      <v-select
        :label="$t('layerSelection')"
        :items="layerSelection"
        v-model="selectedLayer"
        return-object
        solo
        item-text="title"
        class="py-0 "
      ></v-select>
    </div>
    <div class="mx-5">
      <v-row class="py-5">
        <v-col> 1.{{ $t("changeDetectionTool.timestep") }}</v-col>
      </v-row>
      <v-row no-gutters v-if="firstDatetimeSelection.length">
        <v-col cols="10">
          <v-select
            :label="$t('changeDetectionTool.timestep')"
            :items="firstDatetimeSelection"
            return-object
            item-text="datetime"
            v-model="firstSelectedTimeStep"
            class="py-0"
          >
            <v-icon
              slot="prepend"
              :disabled="
                disableNextButton(firstDatetimeSelection, firstSelectedTimeStep)
              "
              @click="
                firstSelectedTimeStep = previousDate(
                  firstDatetimeSelection,
                  firstSelectedTimeStep
                )
              "
            >
              mdi-menu-left
            </v-icon>
            <v-icon
              slot="append-outer"
              :disabled="
                disablePreviousButton(
                  firstDatetimeSelection,
                  firstSelectedTimeStep
                )
              "
              @click="
                firstSelectedTimeStep = nextDate(
                  firstDatetimeSelection,
                  firstSelectedTimeStep
                )
              "
            >
              mdi-menu-right
            </v-icon>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="py-5">
        <v-col> 2.{{ $t("changeDetectionTool.timestep") }}</v-col>
      </v-row>
      <v-row no-gutters v-if="secondDatetimeSelection.length">
        <v-col cols="10">
          <v-select
            :label="$t('changeDetectionTool.timestep')"
            :items="secondDatetimeSelection"
            return-object
            item-text="datetime"
            v-model="secondSelectedTimeStep"
            class="py-0"
          >
            <v-icon
              slot="prepend"
              :disabled="
                disableNextButton(
                  secondDatetimeSelection,
                  secondSelectedTimeStep
                )
              "
              @click="
                secondSelectedTimeStep = previousDate(
                  secondDatetimeSelection,
                  secondSelectedTimeStep
                )
              "
            >
              mdi-menu-left
            </v-icon>
            <v-icon
              slot="append-outer"
              :disabled="
                disablePreviousButton(
                  secondDatetimeSelection,
                  secondSelectedTimeStep
                )
              "
              @click="
                secondSelectedTimeStep = nextDate(
                  secondDatetimeSelection,
                  secondSelectedTimeStep
                )
              "
            >
              mdi-menu-right
            </v-icon>
          </v-select>
        </v-col>
      </v-row>
    </div>
    <!--    <div class="ml-5 mt-10">-->
    <!--      <v-switch-->
    <!--        v-model="fullArea"-->
    <!--        inset-->
    <!--        :label="-->
    <!--            fullArea-->
    <!--              ? $t('changeDetectionTool.useFullArea')-->
    <!--              : $t('changeDetectionTool.selectPolygon')-->
    <!--          "-->
    <!--      ></v-switch>-->
    <!--    </div>-->
    <!--    <div class="mx-15" v-if="!fullArea">-->
    <!--      <v-autocomplete-->
    <!--        class="mx-3"-->
    <!--        v-if="virtualStations.length"-->
    <!--        auto-select-first-->
    <!--        clearable-->
    <!--        dense-->
    <!--        outlined-->
    <!--        hide-details-->
    <!--        hide-selected-->
    <!--        hide-no-data-->
    <!--        item-text="name"-->
    <!--        :label="$t('station')"-->
    <!--        v-model="selectedStation"-->
    <!--        return-object-->
    <!--        :items="virtualStations"-->
    <!--      >-->
    <!--      </v-autocomplete>-->
    <!--    </div>-->
    <div align="center" class="mt-15">
      <v-btn color="primary" rounded @click="startChangeCalculation">
        {{ $t("changeDetectionTool.applyChangeCalculation") }}
        <v-icon class="pl-3" v-if="loading">mdi-spin mdi-loading</v-icon>
      </v-btn>
    </div>
    <change-map-info-dialog
      :open-dialog="showInfo"
      @closeDialog="showInfo = false"
    ></change-map-info-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import ChangeMapInfoDialog from "@/core/components/menu/eotool/analyse/ChangeMapInfoDialog.vue";
import CoastLayerMixin from "@/core/mixins/coastLayer.mixin";
import moment from "moment";

export default {
  name: "ChangeDetectionTool",
  components: { ChangeMapInfoDialog },
  mixins: [CoastLayerMixin],
  data: () => ({
    layerSelection: [],
    selectedLayer: {},
    firstDatetimeSelection: [],
    datetimeSelection: [],
    secondDatetimeSelection: [],
    firstSelectedTimeStep: "",
    secondSelectedTimeStep: "",
    fullArea: true,
    selectedStation: null,
    showInfo: false,
    loading: false
  }),
  computed: {
    ...mapState("management", ["accessToken", "tempResolution"]),
    activeRegionId() {
      return this.$route.params.regionId;
    },
    virtualStations() {
      return this.$store.state.draw.virtualStations;
    }
  },
  methods: {
    ...mapActions("raster", [
      "setRasterLayer",
      "setLayerTimesteps",
      "setTempResLayerTimesteps"
    ]),
    ...mapActions("management", ["getOrFetchAccessToken"]),
    filterLayer(layerSelection, product) {
      const filteredLayer = layerSelection.filter(
        layer => layer.product != product
      );
      return filteredLayer;
    },
    async getAndFilterLayer() {
      let layerSelection = await this.fetchLayers();
      if (layerSelection.length > 0) {
        const data = layerSelection.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        this.layerSelection = this.filterLayer(data, "rgb");
        this.layerSelection = this.layerSelection.filter(
          layer =>
            !layer.layerId.includes("-change-map") &&
            !layer.description.includes("Erosion") &&
            !layer.description.includes("Wave Height")
        );
        const sdbLayer = this.layerSelection.filter(
          layer => layer.product == "sdb"
        );
        if (sdbLayer.length) {
          this.selectedLayer = sdbLayer[0];
        } else {
          this.selectedLayer = data[0];
        }
      }
    },
    async fetchLayers() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.activeRegionId}&token=${this.accessToken}`
      );
      return response.data;
    },
    async fetchTimesteps() {
      const response = await this.$rastless.get(
        `/layers/${this.selectedLayer.layerId}/steps?token=${this.accessToken}`
      );
      this.datetimeSelection = response.data;
      this.firstSelectedTimeStep = response.data[0];
      this.secondSelectedTimeStep = response.data[1];
      this.firstDatetimeSelection = this.datetimeSelection;
      this.secondDatetimeSelection = this.datetimeSelection;
    },

    async postChangeCalculation(layer, timestep1, timestep2, changeMapLayerId) {
      const data = {
        layer_id: layer.layerId,
        timestep_1: timestep1.datetime,
        timestep_2: timestep2.datetime,
        layer_id_change_map: changeMapLayerId,
        product: layer.product
      };
      try {
        await axios.post(`/coasts/region/${this.activeRegionId}`, data);
      } catch (err) {
        console.log(err);
      }
    },
    async lookForChangeMapLayer(layer) {
      const fullLayerSelection = await this.fetchLayers();
      const changeLayerId = `${layer.layerId}-change-map`;
      const existedLayer = fullLayerSelection.filter(
        layer => layer.layerId === changeLayerId
      );
      if (existedLayer.length) {
        return existedLayer[0];
      } else {
        return "";
      }
    },
    async startChangeCalculation() {
      if (!this.fullArea) {
        console.log(this.selectedStation);
      }
      const changeMapLayer = await this.lookForChangeMapLayer(
        this.selectedLayer
      );
      let changeMapLayerId = changeMapLayer?.layerId;
      if (!changeMapLayerId) {
        changeMapLayerId = "";
      }
      const dateArray = [
        this.firstSelectedTimeStep,
        this.secondSelectedTimeStep
      ];
      const sortedArray = dateArray.sort(
        (a, b) => new moment(b.datetime) - new moment(a.datetime)
      );
      this.postChangeCalculation(
        this.selectedLayer,
        sortedArray[0],
        sortedArray[1],
        changeMapLayerId
      );
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.showInfo = true;
      }, 2000);
    }
  },

  watch: {
    selectedLayer: function() {
      this.fetchTimesteps();
    },
    firstSelectedTimeStep() {
      const activeLayerInfo = {
        ...this.firstSelectedTimeStep,
        ...this.selectedLayer
      };
      this.secondDatetimeSelection = this.datetimeSelection.filter(date =>
        moment(date.datetime).isBefore(this.firstSelectedTimeStep.datetime)
      );
      this.$store.dispatch("analyse/setActiveLayer1", activeLayerInfo);
    },
    secondSelectedTimeStep() {
      const activeLayerInfo = {
        ...this.secondSelectedTimeStep,
        ...this.selectedLayer
      };
      this.$store.dispatch("analyse/setActiveLayer2", activeLayerInfo);
    }
  },
  created() {
    this.getOrFetchAccessToken().then(async () => {
      await this.getAndFilterLayer();
      this.fetchTimesteps();
    });
  }
};
</script>

<style scoped></style>
