var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-5",staticStyle:{"align-content":"center"}},[(!_vm.layerSelection.length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("noRasterLayerAvailable"))+" ")]):_c('div',[_c('span',[_vm._v("Choose one layer to create a change map")]),_c('v-select',{staticClass:"py-0",attrs:{"label":_vm.$t('layerSelection'),"items":_vm.layerSelection,"return-object":"","solo":"","item-text":"title"},model:{value:(_vm.selectedLayer),callback:function ($$v) {_vm.selectedLayer=$$v},expression:"selectedLayer"}})],1),_c('div',{staticClass:"mx-5"},[_c('v-row',{staticClass:"py-5"},[_c('v-col',[_vm._v(" 1."+_vm._s(_vm.$t("changeDetectionTool.timestep")))])],1),(_vm.firstDatetimeSelection.length)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{staticClass:"py-0",attrs:{"label":_vm.$t('changeDetectionTool.timestep'),"items":_vm.firstDatetimeSelection,"return-object":"","item-text":"datetime"},model:{value:(_vm.firstSelectedTimeStep),callback:function ($$v) {_vm.firstSelectedTimeStep=$$v},expression:"firstSelectedTimeStep"}},[_c('v-icon',{attrs:{"slot":"prepend","disabled":_vm.disableNextButton(_vm.firstDatetimeSelection, _vm.firstSelectedTimeStep)},on:{"click":function($event){_vm.firstSelectedTimeStep = _vm.previousDate(
                _vm.firstDatetimeSelection,
                _vm.firstSelectedTimeStep
              )}},slot:"prepend"},[_vm._v(" mdi-menu-left ")]),_c('v-icon',{attrs:{"slot":"append-outer","disabled":_vm.disablePreviousButton(
                _vm.firstDatetimeSelection,
                _vm.firstSelectedTimeStep
              )},on:{"click":function($event){_vm.firstSelectedTimeStep = _vm.nextDate(
                _vm.firstDatetimeSelection,
                _vm.firstSelectedTimeStep
              )}},slot:"append-outer"},[_vm._v(" mdi-menu-right ")])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"py-5"},[_c('v-col',[_vm._v(" 2."+_vm._s(_vm.$t("changeDetectionTool.timestep")))])],1),(_vm.secondDatetimeSelection.length)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{staticClass:"py-0",attrs:{"label":_vm.$t('changeDetectionTool.timestep'),"items":_vm.secondDatetimeSelection,"return-object":"","item-text":"datetime"},model:{value:(_vm.secondSelectedTimeStep),callback:function ($$v) {_vm.secondSelectedTimeStep=$$v},expression:"secondSelectedTimeStep"}},[_c('v-icon',{attrs:{"slot":"prepend","disabled":_vm.disableNextButton(
                _vm.secondDatetimeSelection,
                _vm.secondSelectedTimeStep
              )},on:{"click":function($event){_vm.secondSelectedTimeStep = _vm.previousDate(
                _vm.secondDatetimeSelection,
                _vm.secondSelectedTimeStep
              )}},slot:"prepend"},[_vm._v(" mdi-menu-left ")]),_c('v-icon',{attrs:{"slot":"append-outer","disabled":_vm.disablePreviousButton(
                _vm.secondDatetimeSelection,
                _vm.secondSelectedTimeStep
              )},on:{"click":function($event){_vm.secondSelectedTimeStep = _vm.nextDate(
                _vm.secondDatetimeSelection,
                _vm.secondSelectedTimeStep
              )}},slot:"append-outer"},[_vm._v(" mdi-menu-right ")])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-15",attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.startChangeCalculation}},[_vm._v(" "+_vm._s(_vm.$t("changeDetectionTool.applyChangeCalculation"))+" "),(_vm.loading)?_c('v-icon',{staticClass:"pl-3"},[_vm._v("mdi-spin mdi-loading")]):_vm._e()],1)],1),_c('change-map-info-dialog',{attrs:{"open-dialog":_vm.showInfo},on:{"closeDialog":function($event){_vm.showInfo = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }