<template>
  <v-navigation-drawer
    :width="420"
    v-model="showMenuComponent"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <slot></slot>

    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MenuSlot",
  computed: {
    ...mapState("app", ["showMenu"]),
    showMenuComponent: {
      get() {
        return this.showMenu;
      },
      set(value) {
        this.$store.dispatch("app/showMenu", value);
      }
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none;
}
</style>
